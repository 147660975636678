<template>
  <div class="forms answer-quiz-form">
    <div v-if="!loading" class="wrapper">
      <div v-if="data.coverImage && data.coverImage.length" class="coverImage">
        <div v-for="(img, key) in data.coverImage" :key="key" class="wrapper">
          <img :src="getCoverImage(img)" />
        </div>
      </div>

      <h2 v-if="showTitle" class="form-title">
        {{ data.name }}
      </h2>

      <form class="fd-one" @submit.prevent="submit">
        <div class="fields-group">
          <div
            class="fields f_quiz"
            :class="{
              error: errors.has(`quizzes-${q_key}-question`),
              'answer-correct':
                checkModelAnswer(formData.quizzes[q_key]) &&
                formData.quizzes[q_key].choice,
              'answer-incorrect':
                !checkModelAnswer(formData.quizzes[q_key]) &&
                formData.quizzes[q_key].choice,
            }"
            v-for="(quiz, q_key) in data.quizzes"
            :key="q_key"
          >
            <div class="question">
              <label class="field-label">{{ quiz.question }}</label>

              <div v-if="formData.quizzes[q_key].choice" class="sign">
                <MarkCorrect v-if="checkModelAnswer(formData.quizzes[q_key])" />
                <MarkInCorrect
                  v-if="!checkModelAnswer(formData.quizzes[q_key])"
                />
              </div>
            </div>

            <div class="wp">
              <div class="field-type-radio">
                <div
                  v-for="(option, o_key) in quiz.options"
                  :key="o_key"
                  class="field-type-radio-items answers"
                  :class="{
                    correct: option.answer && formData.quizzes[q_key].choice,
                    incorrect: !option.answer && formData.quizzes[q_key].choice,
                  }"
                >
                  <input
                    :id="`quizzes-${q_key}-q-${o_key}-option`"
                    :name="`quizzes-${q_key}-question`"
                    :value="option._id"
                    type="radio"
                    :data-vv-as="quiz.question"
                    :v-validate="o_key == 0 ? 'required' : ''"
                    v-model="formData.quizzes[q_key].choice"
                    :disabled="formData.quizzes[q_key].choice"
                  />

                  <label :for="`quizzes-${q_key}-q-${o_key}-option`">
                    {{ option.value }}
                  </label>
                </div>
              </div>

              <div
                class="error-msg"
                v-if="errors.has(`quizzes-${q_key}-question`)"
              >
                {{ errors.first(`quizzes-${q_key}-question`) }}
              </div>
            </div>
          </div>
        </div>

        <div class="error-msg text-centered" v-if="!allAnswered">
          All questions must be answered
        </div>
      </form>

      <div
        class="percentage-txt font-weight w500 success-msg text-centered"
        v-if="allAnswered"
      >
        <span class="correct" v-if="correctPercent"
          >{{ correctPercent }}% Correct!</span
        >
        <span class="incorrect" v-if="correctPercent == 0"
          >100% Incorrect!</span
        >
      </div>

      <div v-if="allAnswered" class="result-detail">
        <div v-if="!result_loading" class="result-detail-wrapper">
          <div
            v-html="getResultMessage()"
            v-if="getResultMessage()"
            class="message"
          ></div>
        </div>
      </div>

      <div v-if="allAnswered" class="result-detail">
        <div v-if="!result_loading" class="result-detail-wrapper">
          <div v-if="data.images && data.images.length" class="images">
            <div v-for="(img, key) in data.images" :key="key" class="wrapper">
              <img :src="getImage(img._id)" />
            </div>
          </div>

          <div v-if="answer && answer.alias" class="alias text-centered">
            Your ID: <strong>{{ answer.alias }}</strong>
          </div>
        </div>

        <div class="loading-wp" v-if="result_loading">
          <vue-element-loading :active="true" />
        </div>
      </div>
    </div>

    <vue-element-loading :active="loading" is-full-screen />
  </div>
</template>

<script>
import MarkCorrect from "@/assets/figures/MarkCorrect.vue";
import MarkInCorrect from "@/assets/figures/MarkInCorrect.vue";

export default {
  name: "AnswerQuizForm",
  components: {
    MarkCorrect,
    MarkInCorrect,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    form: {
      type: Object,
      default: function () {
        return {};
      },
    },
    afterRedirect: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    return {
      result_loading: false,
      loading: false,
      connection_error: false,

      lastRequest: {},

      data: {},
      resultDetail: {},
      coverImage: "",

      answer: null,

      formData: {
        quizzes: [],
      },
      formValues: {},
    };
  },
  // metaInfo() {
  //   return {
  //     meta: [
  //       { property: "og:title", content: this.data.name },
  //       { property: "og:image", content: this.coverImage },
  //     ],
  //   };
  // },
  methods: {
    fetch: async function () {
      const thisCompo = this;

      this.loading = true;

      const questionForm = await this.$api
        .formList({
          id: this.$route.params.id,
          populate: ["quizzes", "coverImage", "images"],
        })
        .catch((err) => {
          if (err.response) {
            thisCompo.$toasted.show(err.response.data.message, {
              type: "is-danger",
              position: "is-top",
            });
          } else {
            thisCompo.$toasted.show("Connection error", {
              type: "is-danger",
              position: "is-bottom",
            });
          }
        });

      if (questionForm && questionForm.data.list.length) {
        this.data = questionForm.data.list[0];

        this.formData.quizzes = this.data.quizzes.map((quiz) => {
          const answers = quiz.options
            .filter((option) => {
              return option.answer ? true : false;
            })
            .map((option) => option._id);

          return {
            id: quiz._id,
            answers: answers,
            choice: null,
          };
        });
      }

      this.loading = false;
    },
    sendData: async function () {
      const thisCompo = this;

      this.result_loading = true;

      const newData = {
        form: this.data._id,
      };

      if (this.formData.quizzes) {
        newData.quizzes = this.formData.quizzes.map((quiz) => {
          return {
            quiz: quiz.id,
            choice: quiz.choice,
          };
        });
      }

      const answered = await this.$api.answerCreate(newData).catch((err) => {
        if (err.response) {
          thisCompo.$toasted.show(err.response.data.message, {
            type: "is-danger",
            position: "is-top",
          });
        } else {
          thisCompo.$toasted.show("Connection error", {
            type: "is-danger",
            position: "is-bottom",
          });
        }
      });

      this.result_loading = false;

      if (answered) {
        this.answer = answered.data;

        if (!this.$isEmpty(this.afterRedirect)) {
          this.$router.push(this.afterRedirect);
        }

        this.$toasted.show("Answer submitted", {
          type: "is-success",
          position: "is-top",
        });
      }
    },
    submit: function () {
      const thisCompo = this;

      this.$validator.validateAll().then((result) => {
        if (result) {
          thisCompo.sendData();
        } else {
          thisCompo.$toasted.show(
            "Form is not valid! Please check the fields.",
            {
              type: "is-danger",
              position: "is-bottom",
            }
          );
        }
      });
    },

    checkModelAnswer: function (quiz) {
      const answers = quiz.answers;
      const choice = quiz.choice;

      if (choice) {
        return answers.find((ans) => ans == choice) ? true : false;
      }

      return null;
    },
    getCoverImage: function (img) {
      const imgURL = `${this.$configs.api.url}/client/file/image/original/${img.file.unique_name}`;
      this.coverImage = imgURL;
      return imgURL;
    },
    getImage: function (fid) {
      const imgURL = new URL(
        `/client/file/image/original/${fid}`,
        this.$configs.api.url
      );

      return imgURL;
    },
    getResultMessage: function () {
      if (this.data.results) {
        const results = this.data.results.map((result) => {
          return {
            percentage: result.percentage,
            message: result.message,
          };
        });

        results.sort((a, b) => a.percentage - b.percentage);

        let msg = null;

        results.map((result) => {
          if (this.correctPercent >= result.percentage) {
            msg = result.message;
          }
        });

        return msg;
      }
    },
  },
  mounted: function () {
    this.fetch();
  },
  computed: {
    allAnswered: function () {
      const unanswered = this.formData.quizzes.find(
        (quiz) => quiz.choice == null
      );

      if (unanswered || this.$isEmpty(this.data)) {
        return false;
      }

      return true;
    },
    correctPercent: function () {
      if (this.allAnswered) {
        const total = this.data.quizzes.length;

        let correct = 0;

        this.formData.quizzes.map((quiz) => {
          if (this.checkModelAnswer(quiz)) {
            correct++;
          }
        });

        return this.$util.triRate(total, 100, correct);
      }

      return false;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        if (this.allAnswered) {
          this.submit();
        }
      },
    },
  },
};
</script>

<style scoped>
.coverImage {
  margin-bottom: 40px;
}

.wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.form-title {
  font-size: 1.3em;
  font-weight: 600;

  margin-bottom: 50px;

  padding: 16px 15px;
  background-color: #0064ff;
  color: #fff;

  border-radius: 5px;

  box-shadow: 0 0 18px rgba(0, 100, 255, 0.5);
}

.fields-group {
  margin-bottom: 30px;

  display: grid;
  grid-template-columns: 100%;
  grid-gap: 30px;
}
.fields-group:last-child {
  margin-bottom: 0;
}

.wp {
  padding: 0 15px;
}

.question {
  padding: 0 15px;

  padding-bottom: 15px;
  margin-bottom: 15px;

  border-bottom: 1px solid #eee;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  align-items: center;
}

.question .sign svg {
  display: block;
  max-height: 20px;
  width: auto;
}

.field-label {
  display: block;
  cursor: text;

  font-size: 1.1em;
  font-weight: 600;
  color: #333;
}

.fields.f_quiz {
  margin: 0;
  padding: 15px 0;
  background-color: #fff;

  border-radius: 5px;
  overflow: hidden;

  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
}
.fields.f_quiz.answer-incorrect .answers.incorrect label {
  color: #ff4b4b;
}
.fields.f_quiz.answer-incorrect .answers.correct label {
  color: #39ba44;
}
.fields.f_quiz.answer-incorrect .answers.incorrect label:before {
  border-color: #ff4b4b;
  background-color: #ff4b4b;
}
.fields.f_quiz.answer-incorrect .answers.correct label:before {
  border-color: #0dd35a;
  background-color: #0dd35a;
}
.fields.f_quiz.answer-incorrect .answers.correct label:after {
  opacity: 1;
}

.percentage-txt {
  margin-top: 30px;
}
.percentage-txt .correct {
  color: #39ba44;
}
.percentage-txt .incorrect {
  color: #0dd35a;
}

.result-detail {
  margin-top: 30px;
}
.result-detail-wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 30px;
}
.result-detail .loading-wp {
  min-height: 50px;
}
.result-detail .images {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 5px;
}
.result-detail .images img {
  margin: 0 auto;
  min-width: 100%;
  height: auto;
  margin: 0 auto;
}
.result-detail .images .wrapper {
  border-radius: 5px;
  overflow: hidden;
  background-color: #ccc;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
}
.result-detail .message {
  font-size: 20px;
  font-weight: bold;
  line-height: 34px;
  /* color: #0064ff; */
  color: #2e3192;
  /* background-color: #2e3192; */
  border-radius: 5px;
  padding: 15px 15px;
  background-color: #fff;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
}
.result-detail .alias {
  font-size: 0.875em;
  color: #fff;
  border-radius: 5px;
  padding: 15px 15px;
  background-color: #39ba44;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
}
</style>
