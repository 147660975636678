import Vue from 'vue'
import Router from 'vue-router'

import QuizForms from './views/QuizForms.vue'
import AnswerQuiz from './views/AnswerQuiz.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active-trail',
  linkExactActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: '/quiz-forms',
    },
    {
      path: '/quiz-forms',
      name: 'quizForms',
      component: QuizForms,
    },


    {
      path: '/quiz-forms/:id/answer',
      name: 'answerQuiz',
      component: AnswerQuiz,
    },
  ]
})

export default router
