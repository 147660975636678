const pagerCount = (totalItems, limit) => {
  return Math.ceil(totalItems / limit)
}


const triRate = (value, totalRate, rate, reverse=false) => {
  if (!reverse) {
    return (rate / value) * totalRate
  }
  else {
    return (value / rate) * totalRate
  }
}


export default {
  pagerCount: pagerCount,
  triRate: triRate,
}