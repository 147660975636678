import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'

import Axios from 'axios'
import Configs from './configs.js'
import Api from '@/misc/api-requests.js'
import Util from '@/misc/utilities.js'

import isEmpty from 'lodash.isempty'

import { default as VeeValidate, Validator } from 'vee-validate'
import VeeValidateCustom from '@/misc/vee-v-custom.js'

import Toasted from 'vue-toasted'
import Paginate from 'vuejs-paginate'
import VueElementLoading from 'vue-element-loading'

import '@/assets/css/minireset.min.css'
import '@/assets/css/fonts.css'
import '@/assets/css/defaults.css'
import '@/assets/css/layout.css'
import '@/assets/css/forms.css'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(Toasted, {
  theme: 'toasted-primary',
  duration: 3000
})
Vue.use(VeeValidate, {
  fieldsBagName: 'formFields'
})

Vue.prototype.$axios = Axios
Vue.prototype.$api = Api
Vue.prototype.$util = Util
Vue.prototype.$configs = Configs
Vue.prototype.$isEmpty = isEmpty
Vue.prototype.$deepCopy = value => {
  return JSON.parse(JSON.stringify(value))
}

Vue.component('paginate', {
  extends: Paginate,
  props: {
    'prev-class': {
      type: String,
      default: 'pager-control'
    },
    'next-class': {
      type: String,
      default: 'pager-control'
    },
    'prev-text': {
      type: String,
      default: 'previous'
    },
    'next-text': {
      type: String,
      default: 'next'
    },
    'container-class': {
      type: String,
      default: 'pager'
    },
    'page-class': {
      type: String,
      default: 'pages'
    }
  }
})
Vue.component('VueElementLoading', {
  extends: VueElementLoading,
  props: {
    color: {
      type: String,
      default: '#0064ff'
    }
  }
})

Vue.config.productionTip = false
VeeValidateCustom.extend(Validator)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
