<template>
  <div class="pages page-quiz-forms main-layout-1130 padded-lr-15">
    <div class="wrapper">
      <QuizFormsOverview />
    </div>
  </div>
</template>

<script>
import QuizFormsOverview from '@/components/QuizFormsOverview.vue'

export default {
  name: 'QuizForms',
  components: {
    QuizFormsOverview,
  },
}
</script>

<style scoped>
  .pages {
    min-height: 100%;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
  }


  .wrapper {
    padding: 50px 0;
  }
</style>