<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 0C5.152 0 0 5.152 0 11.5C0 17.848 5.152 23 11.5 23C17.848 23 23 17.848 23 11.5C23 5.152 17.848 0 11.5 0ZM2.3 11.5C2.3 6.417 6.417 2.3 11.5 2.3C13.6275 2.3 15.5825 3.0245 17.135 4.2435L4.2435 17.135C3.0245 15.5825 2.3 13.6275 2.3 11.5ZM11.5 20.7C9.3725 20.7 7.4175 19.9755 5.865 18.7565L18.7565 5.865C19.9755 7.4175 20.7 9.3725 20.7 11.5C20.7 16.583 16.583 20.7 11.5 20.7Z" :fill="color"/></svg>
</template>

<script>
  export default {
    name: 'MarkInCorrect',
    props: {
      color: {
        type: String,
        default: '#f01414',
      },
    },
  }
</script>