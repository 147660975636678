<template>
  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.59 1.41L16.18 0L9.84 6.34L11.25 7.75L17.59 1.41ZM21.83 0L11.25 10.58L7.07 6.41L5.66 7.82L11.25 13.41L23.25 1.41L21.83 0ZM0 7.82L5.59 13.41L7 12L1.42 6.41L0 7.82Z" :fill="color"/></svg>
</template>

<script>
  export default {
    name: 'MarkCorrect',
    props: {
      color: {
        type: String,
        default: '#0dd35a',
      },
    },
  }
</script>