<template>
  <div class="pages">
    <div class="layout main-layout-1130 padded-lr-15">
      <div class="wrapper">
        <AnswerQuizForm />
      </div>
    </div>
  </div>
</template>

<script>
import AnswerQuizForm from "@/components/forms/AnswerQuizForm.vue";

export default {
  name: "AnswerQuiz",
  components: {
    AnswerQuizForm,
  },
};
</script>

<style scoped>
.pages {
  background-color: #f8f8f8;

  min-height: 100%;
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
}

.layout {
  width: 100%;
}

.wrapper {
  padding: 50px 0;
}
</style>
