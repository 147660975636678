<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  created: function() {
    this.$axios.defaults.baseURL = this.$configs.api.url
  }
}
</script>