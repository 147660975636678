const boolean = {
  getMessage(field) {
    return "The " + field + " must be boolean"
  },
  validate(value) {
    if (typeof value === "boolean") {
      return true;
    }

    return false;
  },
};

export default {
  extend: function(VeeValidate) {
    VeeValidate.extend('boolean', boolean);
  },
};