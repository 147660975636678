<template>
  <div class="component quiz-forms-overview">
    <vue-element-loading :active="loading" is-full-screen />

    <div class="list">
      <router-link
        v-for="(form, key) in data"
        :to="`/quiz-forms/${form._id}/answer`"
        class="quiz-forms"
        :key="key">

        <div class="name">
          {{ form.name }}
        </div>
      </router-link>

      <paginate
        v-if="total > limit"
        :page-count="$util.pagerCount(total, limit)"
        :click-handler="pageChange">
      </paginate>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizFormsOverview',
  data: function() {
    return {
      loading: false,
      connection_error: false,
      
      data: {},

      limit: 10,
      total: 0,
      lastRequest: {},
    }
  },
  methods: {
    fetch: async function(requestOptions={}) {
      const thisCompo = this

      this.loading = true

      this.lastRequest = requestOptions

      const response = await this.$api.formList(requestOptions).catch( (err) => {
        if (err.response) {
          thisCompo.$toasted.show(err.response.data.message, {
            type: 'is-danger',
            position: 'is-top'
          })
        }
        else {
          thisCompo.$toasted.show('Connection error', {
            type: 'is-danger',
            position: 'is-bottom'
          })

          thisCompo.connection_error = true
        }
      })

      if (response) {
        this.data = response.data.list
        this.limit = response.data.limit
        this.total = response.data.total
      }

      this.loading = false
    },
    pageChange: function(pageNum) {
      this.fetch({
        ...this.lastRequest,
        ...{
          skip: this.limit * ( pageNum - 1 ),
        }
      })
    },
  },
  mounted: function() {
    this.fetch()
  }
}
</script>

<style scoped>
  .list {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px;
  }

  .quiz-forms {
    display: block;

    padding: 15px 25px;
    background-color: #f8f8f8;
    border: 1px solid #eee;
    cursor: pointer;
  }
</style>