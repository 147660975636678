import Vue from 'vue'


const answerCreate = (data) => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/client/answer/create',
    data: data,
  })
}


const formList = (data) => {
  return Vue.prototype.$axios({
    method: 'post',
    url: '/client/form/list',
    data: data,
  })
}


export default {
  answerCreate: answerCreate,
  formList: formList,
}